import React, { useEffect, useState } from 'react';
import LoginContextProvider from './context/LoginContext';
import CheckoutContextProvider from './context/CheckoutContext';
import ProductContextProvider from './context/ProductContext';
import Main from './router/Main';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import config from './config';

const msalInstance = new PublicClientApplication(config);

function App() {

  const [appLoading, setAppLoading] = useState(true);
  useEffect(() => {
    const d = new Date().toISOString().split('T');
    const local = JSON.parse(localStorage.getItem('Newdate'));
    appFuction(local, d[0]);
  }, []);

  const appFuction = async(a, b) => {
    const state = { date: b };
    if(a === null || a.date !== b){
      localStorage.setItem('Newdate', JSON.stringify(state));
      localStorage.removeItem('checkout');localStorage.removeItem('product');localStorage.removeItem('login');
    }
    setAppLoading(false);
  };

  return (
    <div className="App">
      {
        (appLoading) ? null :
        <MsalProvider instance={msalInstance}>
          <LoginContextProvider>
            <CheckoutContextProvider>
              <ProductContextProvider>
                <Main />
              </ProductContextProvider>
            </CheckoutContextProvider>
          </LoginContextProvider>
        </MsalProvider>
      }
    </div>
  );
}

export default App;
