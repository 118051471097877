import { v4 as uuidv4 } from 'uuid';

export const productReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_PRODUCT':
      return [...state, {
        name: action.product.name, 
        uuid: action.product.uuid, 
        qty : action.product.handleqty,
        sky : action.product.sky,
        variant_id : action.product.variant_id,
        image_url : action.product.image_url,
        main_price: action.product.main_price,
        totalQty: action.product.totalQty,
        sp: action.product.sp,
        product_id: action.product.product_id,
        id: uuidv4()}
      ]
    case 'UPDATE_PRODUCT':
      return state.map(obj => obj.sky === action.product.sky ? { ...obj, qty : action.product.handleqty} : obj);
    case 'REMOVE_PRODUCT':
      return state.filter(product => product.id !== action.id);
    case 'EMPTY_PRODUCT':
      return [];
    default:
      return state;
  }
} 