import React, { createContext, useReducer,useEffect } from 'react';
import { loginReducer } from '../reducer/LoginReducer';

export const LoginContext = createContext();

const LoginContextProvider = (props) => {
	const initialState = { isAuthentic: false, id: '', email_id: '', pick: '', product_check: '' };
	
	const[login, dispatch] = useReducer(loginReducer, [], () => {
		const localData = localStorage.getItem('login');
		return localData ? JSON.parse(localData) : initialState;
	});

	useEffect(() => {
		localStorage.setItem('login', JSON.stringify(login));
	}, [login]);
	
	return (
		<LoginContext.Provider value={{ login, dispatch }}>
			{props.children}
		</LoginContext.Provider>
	);
}

export default LoginContextProvider;
