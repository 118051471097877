import React, { createContext, useReducer, useEffect } from 'react';
import { productReducer } from '.././reducer/ProductReducer';

export const ProductContext = createContext();

const ProductContextProvider = (props) => {

	const [product, dispatch] = useReducer(productReducer, [], () => {
		const localData = localStorage.getItem('product');
		return localData ? JSON.parse(localData) : [];
    });
    
	useEffect(() => {
        localStorage.setItem('product', JSON.stringify(product));
    }, [product]);

	return ( 
        <ProductContext.Provider value={{ product, dispatch }}>
            {props.children}
        </ProductContext.Provider> );
}
 
export default ProductContextProvider;