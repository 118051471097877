import React, { useState, useContext, useEffect, Suspense, lazy } from 'react';
import {
	Switch,
	Route,
	Redirect
} from "react-router-dom";
import { LoginContext } from '../context/LoginContext';
import { checkoutContext } from '../context/CheckoutContext';

const Login = lazy(() => import('../component/login/Login'));
const Search = lazy(() => import('../component/search/Search'));
const Cart = lazy(() => import('../component/cart/Cart'));
const Payment = lazy(() => import('../component/checkout/Payment'));
const ChekDP = lazy(() => import('../component/CheckDeliveryandPickup/CheckDP'));

function Main() {

	const [loginSuccess, setLoginSuccess] = useState(false);

	const { login } = useContext(LoginContext);
	const { checkout } = useContext(checkoutContext);

	useEffect(() => {
		if(login.isAuthentic) { setLoginSuccess(true) } else { setLoginSuccess(false) }
	},[login]);

	return (
		<div>
			<Suspense fallback={<div>Loading...</div>}>
				<Switch>
					<Route exact path="/">
						{ (loginSuccess) ? (login.pick.length > 1) ? <Redirect to="/search" />  : <Redirect to="/check" /> : <Redirect to="/login" /> }
					</Route>
					<Route path="/login">
						{ (loginSuccess) ? (login.pick.length > 1) ? <Redirect to="/search" />  : <Redirect to="/check" /> : <Login /> }
					</Route>
					<Route path="/check">{ (loginSuccess) ?  <ChekDP /> : <Redirect to="/login" />  }</Route>
					<Route path="/search">{ (loginSuccess) ? <Search /> : <Redirect to="/login" /> }</Route>
					<Route path="/cart">{ (loginSuccess) ? <Cart /> : <Redirect to="/login" /> }</Route>
					<Route exact path={`/checkout/${checkout.idv4}`}> <Payment /> </Route>
					<Route path={`/checkout`}>{ (loginSuccess) ? <Redirect to="/check" /> : <Redirect to="/login" /> }</Route>
				</Switch>
			</Suspense>
		</div>
	)
}

export default Main
