import React, { createContext, useReducer, useEffect } from 'react';
import { checkoutReducer } from '../reducer/CheckoutReducer';
import { v4 as uuidv4 } from 'uuid';
export const checkoutContext = createContext();

const CheckoutContextProvider = (props) => {
    const initialState = { idv4: uuidv4(), id: '', productTotal: '', shippingTotal: '', subTotal: '', discountApply: false };

    const [checkout, dispatch] = useReducer(checkoutReducer, [], () => {
    const localData = localStorage.getItem('checkout');
		return localData ? JSON.parse(localData) : initialState;
    });
    useEffect(() => {
        localStorage.setItem('checkout', JSON.stringify(checkout));
      }, [checkout]);
      return ( <checkoutContext.Provider value={{ checkout,  dispatch }}>
        {props.children}
    </checkoutContext.Provider> );
}
export default CheckoutContextProvider;