import { v4 as uuidv4 } from 'uuid';

export const checkoutReducer = (state, action) => {
    switch (action.type) {
      case 'ADD_CHECKOUT':
        return {
            idv4: action.checkout.idv4,
            id: action.checkout.id,
            productTotal: action.checkout.productTotal,
            shippingTotal: action.checkout.shippingTotal,
            subTotal: action.checkout.subTotal,
            discountApply: action.checkout.discountApply
          }
        case 'UPDATE_CHECKOUT':
          return  state.idv4 === action.payload.idv4 ? action.payload : state;
        case 'EMPTY_CHECKOUT':
          return { idv4: uuidv4(), id: '', productTotal: '', shippingTotal: '', subTotal: '', discountApply: false }
      default:
        return state;
    }
} 