const config = {
  auth: {
    clientId: "a482bcdb-3590-4bc8-85a4-36a8b4f43111",
    authority:
      "https://login.microsoftonline.com/2c6eb65e-17ad-454c-837c-0c8ec6ec31b9/",
    redirectUri: "https://employee.bestchoiceproducts.com/login", // Set the correct redirect URL
  },
  scopes: ["user.read"],
};

export const loginRequest = {
    scopes: ["User.Read"]
};


export default config;
