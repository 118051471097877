import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { BrowserRouter } from "react-router-dom";

const apolloClient = new ApolloClient({
	cache: new InMemoryCache({
	  typePolicies: {
		UnconventionalRootQuery: {
		  // The RootQueryFragment can only match if the cache knows the __typename
		  // of the root query object.
		  queryType: true,
		},
	  },
	}),
	link: new createHttpLink({
	  uri: `https://bestchoiceproducts.myshopify.com/api/2023-01/graphql.json`,
	  headers: {
		'X-Shopify-Storefront-Access-Token': "e5d72a30b581fa1570807c37b5d55c33",
		'Accept' : 'application/json',
		'Content-Type': 'application/json'
	  },
	}),
  });
  

ReactDOM.render(
  <ApolloProvider client={apolloClient}><BrowserRouter><App /></BrowserRouter></ApolloProvider>,
  document.getElementById('root')
);